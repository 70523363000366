import React from 'react';
import {EC2Client, StartInstancesCommand, StopInstancesCommand, RebootInstancesCommand, DescribeInstancesCommand, DescribeInstanceStatusCommand } from '@aws-sdk/client-ec2';
import './awsConnector.css';
import {Button, Grid, Divider} from '@mui/material'
// import { createTheme } from '@mui/material/styles';
// import { deepOrange, green, grey, yellow } from '@mui/material/colors';
import UserInput from './UserInput'
import $ from 'jquery';
import { server } from './index'
import { check_local_bool } from './app';
// const isReachable = require('is-reachable');

const credentials = {
  accessKeyId: 'AKIAXO6ZPRNIA7QVYDEV',
  secretAccessKey: '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',
}

const instanceID = 'i-095399763854ad4d2'

// window.localStorage.setItem("logged_out", false);

// const redGreen = createTheme({
//   palette: {
//     primary: green,
//     secondary: deepOrange,
//   }
// });
// const greyColor = createTheme({
//   palette: {
//     primary: yellow,
//     secondary: grey
//   }
// });

function getUserName() {
  if (!window.localStorage.getItem("username")) {
    setUserName('Bear');
  }
  return window.localStorage.getItem("username");
}

function setUserName(name) {
  window.localStorage.setItem("username", name);
}

async function getPublicIP() {
  const command = new DescribeInstancesCommand({InstanceIds: [instanceID]});
  return connectToClient(command).then((data, _) => {
    window.localStorage.setItem("ip", data[0].Reservations[0].Instances[0].PublicIpAddress)
    console.log("received instance ip", data[0].Reservations[0].Instances[0].PublicIpAddress)
  }).catch((error) => {console.log(error)})
}

async function connectToClient(command) {
  const client = new EC2Client({region: 'us-east-1', credentials})
  var data;
  try {
    data = await client.send(command);
  } catch (error) {
    data = error
    console.log('COMMAND: ' + command)
    console.log('CONNECTION ERROR: ' + error)
  } finally {
    client.destroy()
  }
  return [data, client];
}

// async function emailAlert(alertString) {
//   $.ajax(`https://maker.ifttt.com/trigger/sp_on/json/with/key/d2UdTbqyVor9rRiez6Qdzs?Value1=${alertString}`, {mode: 'no-cors'});
// }

async function errorFreePoll() {
  // await getPublicIP()
  try {
    const p = await fetch(server('/poll'));
    return p.json();
  } catch {
    console.log('Poll Fetch Failed')
  }
  return {'error': 'true'};
}

// async function isHostReachable() {
//   return await isReachable('http://52.1.164.123:5000')
// }
function get_subtitle() {
  if (window.localStorage.getItem("instance_state") === 2) {
    return 'Connecting...'
  } else if (window.localStorage.getItem("instance_state") === 1) {
    return 'Building server...\nWait time is 3-6 minutes.'
  } else if (window.localStorage.getItem("instance_state") === 0) {
    return 'Server is off.'
  }
  return 'Checking connection:'
}

export default function AWS(props) {
  // window.onload = () => pollInstance(()=>checkUserAccess(false));
  React.useEffect(() => {
    window.localStorage.setItem("time_server_up", undefined);
    awaitConnection(500, 10000, true);
  });
  pollInstance();
  const [color, setColor] = React.useState(undefined);
  const [subtitle, setSubtitle] = React.useState(get_subtitle());
  const [accessMessage, setAccessMessage] = React.useState('Access Status:');
  const [enabled, setEnabled] = React.useState(undefined);
  const [interval, setGlobalInterval] = React.useState(undefined);
  const [name, setName] = React.useState(getUserName());
  const [access, setAccess] = React.useState(false);
  const controller = new AbortController();
  const [instanceState, setInstanceState] = React.useState(undefined);

  async function getInstanceStateFallback() {
    const command = new DescribeInstancesCommand({InstanceIds: [instanceID]});
    return connectToClient(command).then((data, _) => {
      let state = data[0].Reservations[0].Instances[0].State.Name
      if (state === 'pending') {
        state = 1;
        window.localStorage.setItem("instance_state", state);
        setInstanceState(state)
        return state
      } else {
        state = 0;
        window.localStorage.setItem("instance_state", state);
        setInstanceState(state)
        return state
      }
      
    }).catch((error) => {console.log(error)})
  }

  async function getInstanceState() {
    const command = new DescribeInstanceStatusCommand({InstanceIds: [instanceID]});
    return connectToClient(command).then((data, _) => {

      console.log(data[0])
      let status = data[0].InstanceStatuses[0].InstanceStatus.Status
      let state = data[0].InstanceStatuses[0].InstanceState.Name
      // console.log(status, state)
      if (state === 'pending' || status === 'initializing' || check_local_bool("rebooting")) {
        state = 1;
      } else if (state === 'running' && status === 'ok') {
        window.localStorage.setItem("rebooting", false);
        state = 2;
      } else {
        state = 0;
      }
      window.localStorage.setItem("instance_state", state);
      setInstanceState(state)
      return state
    }).catch((error) => getInstanceStateFallback())
  }

  async function requestAccess() {
    try {
      // console.log("Requesting access for user: " + name)
      await $.post(server('/setuser'), {
        'user': name
      })
      const remoteUser = await fetch(server('/getuser'));
      const username = await remoteUser.json();
      if (username.user) {
        return [username.user === name, 'Remote username != local.'];
      } else if (username.error) {
        return [false, username.error]
      } else {
        return [false, 'Failed to retrieve a reason.']
      }
    } catch (e){
      console.log("Encoutered error in requestAccess: " + e.text)
      return [false, 'Error while requesting access.'];
    }
  }

  async function checkUserAccess(toForceMessage) {
    if (check_local_bool("logged_out") && !toForceMessage) {
      // console.log('User logged out.') 
      return false
    }
    window.localStorage.setItem("logged_out", false);
    const accessResult = await requestAccess();
    window.localStorage.setItem("connected", accessResult[0]);
    setAccess(accessResult[0]);
    if (accessResult[0] || toForceMessage) {
      setAccessMessage(buildAccessMessage(accessResult))
    }
    return accessResult[0];
  }

  function buildAccessMessage(accessVar) {
    return ('Access Status: ' + (accessVar[0] ? 'APPROVED' : 'NOT APPROVED\nReason: ' + accessVar[1]))
  }

  async function userButtonHandler() {
    setUserName(name)
    await checkUserAccess(true)
  }

  async function pollInstance(connectedFunc=()=>undefined, failedFunc=()=>undefined, onTimeout=(()=>(controller.abort()))) {
    await getInstanceState()
    // console.log(instanceState)
    if (instanceState === 0) {
      setSubtitle('Server is off.')
    } else if (instanceState === 1 && !check_local_bool("rebooting")) {
      setSubtitle('Building server...\nWait time is 3-6 minutes.')
    } else if (instanceState === 2 || check_local_bool("rebooting")) {
      window.localStorage.setItem("connected", false);
      window.localStorage.setItem("time_server_up", Date.now())
      // Set default behavior if not defined
      const ifConnected = function() {
        // getPublicIP();
        setEnabled(true);
        setSubtitle('Connected.');
        setColor("success");
        checkUserAccess(false);
        window.localStorage.setItem("rebooting", false);
        connectedFunc();
      }
      const ifFailed = function() {
        if (!check_local_bool("rebooting")) {
          setEnabled(false);
          setSubtitle('Server finished building.\nStarting up and waiting to connect...\nWait time is ~20 seconds.');
          setColor("error");
          if (Date.now() - window.localStorage.getItem("time_server_up") > 5000) {
            window.localStorage.setItem("time_server_up", Date.now());
            window.location.reload();
          }
          failedFunc();
        } else {
          setSubtitle('Rebooting...');
          // setColor(undefined);
        }
      }

      let timeout = setTimeout(() => {
        onTimeout();
        ifFailed();
      } , 500);
      errorFreePoll().then(data => {
        clearTimeout(timeout)
        if (data.connected === 'true') {
          ifConnected();
        } else {
          ifFailed();
        }
      });
    }
    
  }

  function awaitConnection(pollInterval, overallTimeout, dont_force) {
    if (interval) {
      if (dont_force) {
        return;
      }
      clearInterval(interval)
    }
    const localInterval = setInterval( () => {
      pollInstance(()=>clearInterval(interval))
    }, pollInterval);
    // setTimeout(()=> {
    //   if (color !== "success") {
    //     clearInterval(interval)
    //     console.log('Failed to connect - timed out.')
    //     // setSubtitle('Failed to connect - timed out.');
    //   }
    // }, overallTimeout);
    setGlobalInterval(localInterval);
  }

  async function startInstance() {
    const command = new StartInstancesCommand({InstanceIds: [instanceID]});
    // setSubtitle("Starting up server...")
    window.localStorage.setItem("started_instance", true);
    // awaitConnection(15000);
    return connectToClient(command);
  }
  
  async function stopInstance() {
    const command = new StopInstancesCommand({InstanceIds: [instanceID]});
    // setSubtitle("Shutting down server...")
    window.localStorage.setItem("started_instance", false);
    setEnabled(false);
    return connectToClient(command);
  }

  async function restartInstance() {
    const command = new RebootInstancesCommand({InstanceIds: [instanceID]});
    setSubtitle("Rebooting...")
    window.localStorage.setItem("rebooting", true);
    // awaitConnection(15000);
    return connectToClient(command);
  }
  // const v2 = (
  //   <Grid container direction='row' >
  //     <Grid item >
  //       <Divider orientation="vertical" height='100%' sx={{border: '2px solid #003262'}}/>
  //     </Grid>
  //     <Grid item marginTop={1}>
  //       <Button variant="contained" color={"success"} disabled={enabled ?? true} onClick={startInstance} style={{height: 50, width: 100}} >
  //         Start Instance
  //       </Button>
  //     </Grid>
  //     <Grid item marginTop={1}>
  //       <Button variant="contained" color={"error"} disabled={!(enabled ?? false)} onClick={stopInstance} style={{height: 50, width: 100}} >
  //         Stop Instance
  //       </Button>
  //     </Grid>
  //   </Grid>
  // )

  const Login = () => (
    <Grid container spacing={2} direction='column' >
      <Grid item>
        <UserInput label={'Login:'} text={name} setText={setName} buttonHandler={userButtonHandler} disabled={access[0]} />
      </Grid>
      <Grid item >
        <div className="subtitle">{accessMessage}</div>
      </Grid> 
    </Grid>
  );

  function clearUser() {
    fetch(server('/clearuser')).then(data => {
      setAccess(false);
      setAccessMessage(buildAccessMessage([false, 'Logged out.']))
    })
    window.localStorage.setItem("logged_out", true);
  }

  const Logout = () => (
    <Grid container spacing={2} direction='column' >
      <Grid item>
       <div className="subtitle" margintop={3}>{accessMessage}</div>
      </Grid>
      <Grid item >
        <Button variant="contained" onClick={clearUser} color='warning' marginleft={15}>
          LOGOUT
        </Button>
      </Grid> 
    </Grid>
  );
  

  const LoginPlex = (enabled) => {
    if (enabled) {
      if (access) {
        return Logout();
      }
      return Login();
    }
    return (
      <div />
    )
  }
 
  return (
    <div className="login-form" style={{height: '100vh'}}>
      <div className="title">AWS Server UI</div>
      <div style={{height: '75vh'}}>
        <Grid container spacing={2} direction='column' >
          <Grid container spacing={2} direction='row' >
            <Grid item >
              <Button variant="contained" color={"success"}  onClick={startInstance} style={{height: 50, width: 100}} > {/*disabled={enabled ?? true}*/}
                Start Instance
              </Button>
            </Grid>
            <Grid item >
              <Button variant="contained" color={"error"} onClick={stopInstance} style={{height: 50, width: 100}} > {/*disabled={!(enabled ?? false)}*/}
                Stop Instance
              </Button>
            </Grid>
          </Grid>
          <Grid item >
            <div className="subtitle">Status:</div>
            <div className="subtitle">{subtitle}</div>
          </Grid>
          <Divider orientation="horizontal" width='100%' sx={{border: 'thin solid #003262', marginTop: 5}}/>
          {LoginPlex(enabled)}
        </Grid>
      </div>
      <Button variant="contained" color={"info"} onClick={restartInstance} style={{height: 50, width: 200, marginTop: 5}} >
        Troubleshoot
      </Button>
    </div>
  )
}
